export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  SeparatePaymentsStyleCart: 'specs.stores.SeparatePaymentsStyleCart',
  EXPRESS_CASHIER_BI_FIX: 'specs.stores.ExpressCashierBiFix',
  UseCommonProductLineItem: 'specs.stores.UseCommonProductLineItem',
  CreateCheckoutFromCart: 'specs.stores.CreateCheckoutFromCart',
  UseNewCartAndCheckoutMutationForQa: 'specs.stores.UseNewCartAndCheckoutMutationForQa',
  CartApiFastFlowWithCreateCheckout: 'specs.stores.CartApiFastFlowWithCreateCheckout',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  isPreOrderEnabled: 'specs.stores.PreOrderClient',
} as const;
